import { Injectable } from '@angular/core';
import {
	doc,
	DocumentReference,
	getDoc,
	getDocs,
	query,
	where,
} from '@angular/fire/firestore';
import { CrudService } from '@context/frontend/api-client';
import { Content, createPath } from '@context/shared/types/common';
import { ContentFile, createFile } from '@context/shared/types/file';
import { ContentFolder, createFolder } from '@context/shared/types/folder';
import { BehaviorSubject } from 'rxjs';

type FetchContentOptionsBase = {
	fetchAll?: boolean;
	parent?: DocumentReference<ContentFolder> | null | never;
};

interface FetchAllContentOptions extends FetchContentOptionsBase {
	fetchAll: true;
	parent?: never;
}

interface FetchParentContentOptions extends FetchContentOptionsBase {
	fetchAll?: false;
	parent: DocumentReference<ContentFolder> | null;
}

type FetchContentOptions =
	| FetchContentOptionsBase
	| FetchAllContentOptions
	| FetchParentContentOptions;

/**
 * Although the inherited `create` function will work, it is recommended to
 * use the `createFolder` or `createFile` functions instead to ensure the correct
 * type is appended to the entity.
 */
@Injectable({ providedIn: 'root' })
export class ContentService extends CrudService<Content> {
	static readonly CollectionId = 'content';

	/**
	 * If a folder is currently being viewed, that folder's id will be stored here.
	 */
	readonly openFolderId = new BehaviorSubject<string | null>(null);

	constructor() {
		super(ContentService.CollectionId);
	}

	/**
	 * Although the inherited `create` function will work, it is recommended to
	 * use the `createFolder` or `createFile` functions instead to ensure the correct
	 * type is appended to the entity.
	 */
	override create(payload: Partial<Content>) {
		if (!payload.type)
			throw new Error('A type is required for a content entity');
		return super.create(payload);
	}

	createFolder(payload: Partial<ContentFolder>) {
		return this.create(createFolder(payload));
	}

	fetchFolders(options: FetchContentOptions) {
		return this.fetchFolderReferences(options).then(({ docs }) =>
			docs.map((doc) => doc.data() as ContentFolder),
		);
	}

	fetchFolderReferences(options: FetchContentOptions) {
		const constraints = [
			where('deletedAt', '==', null),
			where('type', '==', 'folder'),
		];

		if (!options.fetchAll) {
			constraints.push(where('parent', '==', options.parent));
		}

		return getDocs(query(this.collection, ...constraints)).then((res) => {
			// set docs to the cache for quick use elsewhere
			res.docs.forEach((doc) =>
				this.items.set(doc.id, doc.data() as Content),
			);
			return res;
		});
	}

	createFile(payload: Partial<ContentFile>) {
		return this.create(createFile(payload));
	}

	fetchFiles(options: FetchContentOptions) {
		return this.fetchFileReferences(options).then(({ docs }) =>
			docs.map((doc) => doc.data() as ContentFolder),
		);
	}

	fetchFileReferences(options: FetchContentOptions) {
		const constraints = [
			where('deletedAt', '==', null),
			where('type', '==', 'folder'),
		];

		if (!options.fetchAll) {
			constraints.push(where('parent', '==', options.parent));
		}

		return getDocs(query(this.collection, ...constraints)).then((res) => {
			// set docs to the cache for quick use elsewhere
			res.docs.forEach((doc) =>
				this.items.set(doc.id, doc.data() as Content),
			);
			return res;
		});
	}

	fetchContent(options: FetchContentOptions) {
		return this.fetchContentReferences(options).then(({ docs }) =>
			docs.map((doc) => doc.data() as Content),
		);
	}

	fetchContentReferences(options: FetchContentOptions) {
		const constraints = [where('deletedAt', '==', null)];
		if (!options.fetchAll) {
			constraints.push(where('parent', '==', options.parent));
		}

		return getDocs(query(this.collection, ...constraints)).then((res) => {
			// set docs to the cache for quick use elsewhere
			res.docs.forEach((doc) =>
				this.items.set(doc.id, doc.data() as Content),
			);
			return res;
		});
	}

	setOpenFolder(value: string | null) {
		const current = this.openFolderId.value;
		if (current !== value) {
			this.openFolderId.next(value);
		}
	}

	async generateFolderPath(
		content: Content,
	): Promise<ContentFolder[] | null> {
		if (!content.path) return null;

		const path: ContentFolder[] = [];
		for (const segment of content.path) {
			let data = this.items.get(segment.id) as ContentFolder;
			if (!data) {
				data = (await getDoc(
					segment as DocumentReference<ContentFolder>,
				).then((res) => res.data() as ContentFolder)) as ContentFolder;
				this.items.set(data.id, data);
			}
			path.push(data);
		}

		return path;
	}

	/**
	 * Moves a piece of content to another folder and updates all of the necessary
	 * paths and references along the way. This has potential to be a taxing operation
	 * so it is recommended to not abuse this. Use sparingly.
	 *
	 * @param content the content to move into another folder
	 * @param moveTo the folder reference to move the provided content to
	 */
	async moveContentToFolder(
		content: Content,
		moveTo: DocumentReference<ContentFolder> | null,
	) {
		const moveToFolder = moveTo
			? ((await getDoc(moveTo)).data() as ContentFolder)
			: null;

		content.parent = moveTo;
		content.path = createPath(content, moveToFolder?.path ?? []);
		const updatePayload = [content];

		// we need the document reference to the content being moved for querying
		const docRef = doc(this.collection, content.id);

		// retrieve all of the content where the moving content is included in
		const moveableContentSnapshot = await getDocs(
			query(this.collection, where('path', 'array-contains', docRef)),
		);

		for (const doc of moveableContentSnapshot.docs) {
			const item = doc.data() as Content;

			if (item.path?.length) {
				const parentIndex = item.path?.findIndex(
					({ id }) => id === content.id,
				);

				if (parentIndex > 0)
					item.path?.splice(0, parentIndex, ...(content.path ?? []));
			}

			updatePayload.push(item);
		}

		return this.batchUpdate(updatePayload, {
			addActivityLog: true,
			type: 'move-to',
		}).then(() => content);
	}
}
