import { Component, HostBinding, Input } from '@angular/core';
import { RelativeTimePipe } from '@context/frontend/common';
import { getUaDevice } from '@context/frontend/native/platform';
import { Content } from '@context/shared/types/common';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiIcon, TuiSizeL, TuiSizeS } from '@taiga-ui/core';
import { ContentThumbnailComponent } from '../content-thumbnail/content-thumbnail.component';

@Component({
	standalone: true,
	imports: [
		ContentThumbnailComponent,
		RelativeTimePipe,
		TranslocoModule,
		TuiIcon,
	],
	selector: 'ctx-content-row',
	templateUrl: 'content-row.component.html',
	styleUrl: 'content-row.component.scss',
})
export class ContentRowComponent {
	@HostBinding('attr.device')
	readonly device = getUaDevice();

	@Input()
	content!: Content;

	@HostBinding('attr.size')
	@Input()
	size: TuiSizeL | TuiSizeS = 'l';

	@Input()
	label: string | null = null;

	@HostBinding('attr.as-table-row')
	@Input()
	asTableRow = true;
}
