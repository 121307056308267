import { Component, EventEmitter, Output } from '@angular/core';
import { Entity } from '@context/shared/types/common';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiIcon, TuiOption } from '@taiga-ui/core';
import { TuiHeader } from '@taiga-ui/layout';

@Component({
	standalone: true,
	imports: [TuiButton, TuiIcon, TuiOption, TranslocoModule, TuiHeader],
	selector: 'ctx-notification-menu-dialog',
	templateUrl: 'notification-menu-dialog.component.html',
	styleUrl: 'notification-menu-dialog.component.scss',
})
export class NotificationMenuDialogComponent<T extends Entity> {
	@Output()
	readonly dismiss = new EventEmitter<void>();

	@Output()
	readonly markAsRead = new EventEmitter<void>();

	@Output()
	readonly markAsDismissed = new EventEmitter<void>();

	onMarkAsRead() {
		this.markAsRead.emit();
	}

	onMarkAsDismissed() {
		this.markAsDismissed.emit();
	}

	onDismiss() {
		this.dismiss.emit();
	}
}
