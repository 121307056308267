import { NgComponentOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	Input,
	OnInit,
} from '@angular/core';
import { getDoc } from '@angular/fire/firestore';
import { RelativeTimePipe } from '@context/frontend/common';
import { AvatarComponent, UserNamePipe } from '@context/frontend/user';
import { Notification } from '@context/shared/types/notification';
import { User } from '@context/shared/types/user';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiHint } from '@taiga-ui/core';
import { DocumentReference } from 'firebase/firestore';
import { QuillModule } from 'ngx-quill';

@Component({
	standalone: true,
	imports: [
		AvatarComponent,
		RelativeTimePipe,
		QuillModule,
		NgComponentOutlet,
		TranslocoModule,
		TuiHint,
		UserNamePipe,
	],
	selector: 'ctx-notification-row',
	templateUrl: 'notification-row.component.html',
	styleUrl: 'notification-row.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationRowComponent implements OnInit {
	@Input() notification!: Notification;

	sender: User | null = null;

	@HostBinding('attr.unread')
	get unread() {
		return !this.notification?.acknowledgedAt;
	}

	constructor(private readonly cdRef: ChangeDetectorRef) {}

	ngOnInit() {
		if (this.notification.createdBy) this.fetchSender();
	}

	async fetchSender() {
		if (!this.notification.createdBy) return;
		const doc = await getDoc(
			this.notification.createdBy as DocumentReference<User>,
		);
		this.sender = doc.data() as User;
		this.cdRef.detectChanges();
	}
}
