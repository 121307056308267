<!-- Desktop Header -->
<div class="navigation-template__header-desktop">
	<div class="navigation-template__header-desktop-left">
		<button
			tuiIconButton
			appearance="ghost"
			size="l"
			(click)="onToggleSidebar()"
		>
			@if (sidebarCollapsed) {
				<tui-icon size="l" icon="@tui.menu"></tui-icon>
			} @else {
				<tui-icon size="l" icon="@tui.arrow-left-to-line"></tui-icon>
			}
		</button>

		<h1>{{ 'app.name' | transloco }}</h1>
	</div>

	<div class="navigation-template__header-desktop-right">
		<ctx-avatar routerLink="profile" [user]="authService.user"></ctx-avatar>
		<div class="navigation-template__header-desktop-right-name">
			<p class="font-bold">{{ authService.user | userName }}</p>
			<p class="font-size-xsmall color-tertiary">
				{{ authService.organization?.name }}
			</p>
		</div>
	</div>
</div>

<!-- Mobile Header -->
<div class="navigation-template__header-mobile">
	<button
		tuiIconButton
		class="secondary"
		appearance="ghost"
		size="m"
		(click)="onShowMobileMenu(mobileMenu)"
	>
		<tui-icon size="l" icon="@tui.menu"></tui-icon>
	</button>
</div>

<!-- Desktop and Mobile Main Stage -->
<div class="navigation-template__main">
	<!-- Desktop Sidebar -->
	<div class="navigation-template__main-sidebar">
		<div class="navigation-template__main-sidebar-top">
			@if (sidebarCollapsed) {
				<button
					tuiIconButton
					iconStart="@tui.plus"
					size="l"
					appearance="primary"
					(click)="onPromptCreate()"
				></button>
			} @else {
				<button
					tuiButton
					iconStart="@tui.plus"
					size="l"
					appearance="primary"
					(click)="onPromptCreate()"
				>
					{{ 'action.create-new' | transloco }}
				</button>
			}
		</div>

		<div class="navigation-template__main-sidebar-navigation">
			@for (item of menuItems; track item.key) {
				@if (
					(item.location === 'side' || item.location === 'all') &&
					(!item.requiredRole || (item.requiredRole | roleAccess))
				) {
					@if (item.badge$ | async; as count) {
						<tui-badged-content>
							<tui-badge-notification tuiSlot="top">
								{{ count }}
							</tui-badge-notification>

							<ng-container
								*ngTemplateOutlet="
									sidebarButtonContent;
									context: { item }
								"
							></ng-container>
						</tui-badged-content>
					} @else {
						<ng-container
							*ngTemplateOutlet="
								sidebarButtonContent;
								context: { item }
							"
						></ng-container>
					}
				}
			}
		</div>
	</div>

	<div class="navigation-template__main-route-container">
		<router-outlet />
	</div>
</div>

<!-- Mobile Tab Bar -->
<nav class="navigation-template__tab-bar" tuiTabBar>
	@for (item of menuItems; track item.key) {
		@if (
			(item.location === 'tab' || item.location === 'all') &&
			(!item.requiredRole || (item.requiredRole | roleAccess))
		) {
			<tui-badged-content>
				@if (item.badge$ | async; as count) {
					<tui-badge-notification tuiSlot="top">
						{{ count }}
					</tui-badge-notification>
				}

				@if (item.handler) {
					<a
						[icon]="item.icon"
						tuiTabBarItem
						(click)="item.handler()"
					>
						{{ 'navigation.' + item.key | transloco }}
					</a>
				} @else if (item.route) {
					<a
						[icon]="item.icon"
						routerLinkActive="active"
						tuiTabBarItem
						[routerLink]="item.route"
					>
						{{ 'navigation.' + item.key | transloco }}
					</a>
				}
			</tui-badged-content>
		}
	}
</nav>

<ng-template #mobileMenu>
	<ctx-mobile-menu-dialog
		(dismiss)="onDismissMobile()"
		[menuItems]="menuItems"
	></ctx-mobile-menu-dialog>
</ng-template>

<ng-template #logoutTemplate="polymorpheus" polymorpheus>
	<ctx-logout-dialog (dismiss)="onDismissLogout($event)"></ctx-logout-dialog>
</ng-template>

<ng-template #folderTemplate="polymorpheus" polymorpheus="">
	<ctx-folder-dialog (dismiss)="onDismissFolder()"></ctx-folder-dialog>
</ng-template>

<ng-template #createTemplate="polymorpheus" polymorpheus>
	<header tuiHeader>
		<h3 class="margin-0" tuiTitle>{{ 'navigation.create' | transloco }}</h3>

		<div tuiAccessories>
			<button
				tuiIconButton
				size="m"
				(click)="onDismissCreate()"
				appearance="ghost"
			>
				<tui-icon icon="@tui.x"></tui-icon>
			</button>
		</div>
	</header>

	<div class="dialog-menu__content">
		@for (entity of entityTypes; track entity.key) {
			@if (!entity.requiredRole || (entity.requiredRole | roleAccess)) {
				<button
					tuiButton
					class="menu-button"
					[iconStart]="entity.icon"
					iconEnd="@tui.chevron-right"
					(click)="onCreate(entity.key)"
				>
					<span>{{ 'data.' + entity.key | transloco }}</span>
				</button>
			}
		}
	</div>
</ng-template>

<ng-template #sidebarButtonContent let-item="item">
	@if (item.handler) {
		<a
			tuiLink
			size="l"
			class="nav-button"
			[iconStart]="item.icon"
			(click)="item.handler()"
		>
			{{ 'navigation.' + item.key | transloco }}
		</a>
	} @else {
		<a
			tuiLink
			size="l"
			[iconStart]="item.icon"
			class="nav-button"
			[routerLink]="[item.route]"
			routerLinkActive="active"
		>
			{{ 'navigation.' + item.key | transloco }}
		</a>
	}
</ng-template>
