import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AlertService } from '@context/frontend/alert';
import { ContentService } from '@context/frontend/content';
import { ContentFolder } from '@context/shared/types/folder';
import { DocumentReference } from 'firebase/firestore';

type ContentFolderResolveData = {
	data: ContentFolder;
	ref: DocumentReference<ContentFolder>;
};

@Injectable({ providedIn: 'root' })
export class FolderDetailResolver
	implements Resolve<ContentFolderResolveData | null>
{
	readonly contentService = inject(ContentService);
	readonly router = inject(Router);
	readonly alert = inject(AlertService);

	async resolve(
		route: ActivatedRouteSnapshot,
	): Promise<ContentFolderResolveData | null> {
		const id = route.paramMap.get('id');

		if (id) {
			const contentRef = await this.contentService
				.findById(id)
				.catch(() => this.redirect());

			const content = contentRef.data() as ContentFolder;

			// check the content type, and if for some reason, the id's got mixed up
			// we can reroute the user to the correct url based on the type
			if (content.type === 'folder') {
				return {
					data: content as ContentFolder,
					ref: contentRef.ref as DocumentReference<ContentFolder>,
				};
			} else if (content.type === 'file') {
				return this.router
					.navigate(['/home', 'file', content.id])
					.then(() => Promise.resolve(null));
			}
		}

		return this.redirect();
	}

	redirect() {
		return this.router.navigate(['/home']).then(() => {
			this.alert.open('folder-not-found').subscribe();
			return Promise.reject();
		});
	}
}
