import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import {
	AuthService,
	AvatarComponent,
	UserNamePipe,
} from '@context/frontend/user';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiIcon, TuiLink } from '@taiga-ui/core';
import { TuiHeader } from '@taiga-ui/layout';
import { MenuItem } from '../../components';

@Component({
	standalone: true,
	imports: [
		TuiIcon,
		TuiButton,
		TranslocoModule,
		AvatarComponent,
		UserNamePipe,
		TuiLink,
		RouterLink,
		TuiHeader,
		RouterLinkActive,
	],
	selector: 'ctx-mobile-menu-dialog',
	templateUrl: 'mobile-menu-dialog.component.html',
	styleUrl: 'mobile-menu-dialog.component.scss',
})
export class MobileMenuDialogComponent {
	@Input() menuItems: MenuItem[] = [];

	@Output() dismiss = new EventEmitter<void>();

	constructor(public readonly authService: AuthService) {}

	onDismiss() {
		this.dismiss.emit();
	}
}
