import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { inject, Pipe } from '@angular/core';
import { TUI_DEFAULT_MATCHER } from '@taiga-ui/cdk/constants';
import { tuiIsPresent, tuiPure } from '@taiga-ui/cdk/utils/miscellaneous';
import { TUI_DATA_LIST_HOST } from '@taiga-ui/core/components/data-list';
import { TuiTextfieldComponent } from '@taiga-ui/core/components/textfield';
import { tuiIsFlat } from '@taiga-ui/kit/utils';

// TODO: Consider replacing TuiTextfieldComponent with proper token once we refactor textfields
class TuiFilterByInputPipe {
  constructor() {
    // TODO: Remove optional after legacy controls are dropped
    this.textfield = inject(TuiTextfieldComponent, {
      optional: true
    });
    this.host = inject(TUI_DATA_LIST_HOST);
  }
  transform(items, matcher = TUI_DEFAULT_MATCHER) {
    return this.filter(items, matcher, this.host.stringify || String, this.textfield?.el?.nativeElement.value || this.host.nativeFocusableElement?.value || '');
  }
  filter(items, matcher, stringify, query) {
    if (!items) {
      return null;
    }
    return tuiIsFlat(items) ? this.filterFlat(items, matcher, stringify, query) : this.filter2d(items, matcher, stringify, query);
  }
  filterFlat(items, matcher, stringify, query) {
    const match = this.getMatch(items, stringify, query);
    return tuiIsPresent(match) ? items : items.filter(item => matcher(item, query, stringify));
  }
  filter2d(items, matcher, stringify, query) {
    const match = items.find(item => tuiIsPresent(this.getMatch(item, stringify, query)));
    return tuiIsPresent(match) ? items : items.map(inner => this.filterFlat(inner, matcher, stringify, query));
  }
  getMatch(items, stringify, query) {
    return items.find(item => stringify(item).toLocaleLowerCase() === query.toLocaleLowerCase());
  }
  static {
    this.ɵfac = function TuiFilterByInputPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiFilterByInputPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "tuiFilterByInput",
      type: TuiFilterByInputPipe,
      pure: false,
      standalone: true
    });
  }
}
__decorate([tuiPure], TuiFilterByInputPipe.prototype, "filter", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFilterByInputPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'tuiFilterByInput',
      pure: false
    }]
  }], null, {
    filter: []
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiFilterByInputPipe };
