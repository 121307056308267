import * as i0 from '@angular/core';
import { Directive, Input, Component, ChangeDetectionStrategy } from '@angular/core';
import { WaResizeObserver } from '@ng-web-apis/resize-observer';
const _c0 = ["*", [["tui-badge-notification", "tuiSlot", "top"]], [["", "tuiSlot", "top"]], [["tui-badge-notification", "tuiSlot", "bottom"]], [["", "tuiSlot", "bottom"]]];
const _c1 = ["*", "tui-badge-notification[tuiSlot='top']", "[tuiSlot='top']", "tui-badge-notification[tuiSlot='bottom']", "[tuiSlot='bottom']"];
class TuiBadgedContentDirective {
  constructor() {
    this.tuiSlot = 'top';
  }
  static {
    this.ɵfac = function TuiBadgedContentDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiBadgedContentDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiBadgedContentDirective,
      selectors: [["", "tuiSlot", ""]],
      inputs: {
        tuiSlot: "tuiSlot"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiBadgedContentDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiSlot]'
    }]
  }], null, {
    tuiSlot: [{
      type: Input
    }]
  });
})();
class TuiBadgedContentComponent {
  static {
    this.ɵfac = function TuiBadgedContentComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiBadgedContentComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiBadgedContentComponent,
      selectors: [["tui-badged-content"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c1,
      decls: 11,
      vars: 4,
      consts: [["badgeTop", ""], ["badgeBottom", ""], [1, "t-badge", "t-badge_top", 3, "waResizeObserver"], [1, "t-border"], [1, "t-badge", "t-badge_bottom", 3, "waResizeObserver"]],
      template: function TuiBadgedContentComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵprojection(0);
          i0.ɵɵelementStart(1, "div", 2, 0);
          i0.ɵɵlistener("waResizeObserver", function TuiBadgedContentComponent_Template_div_waResizeObserver_1_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(0);
          });
          i0.ɵɵprojection(3, 1);
          i0.ɵɵelementStart(4, "div", 3);
          i0.ɵɵprojection(5, 2);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(6, "div", 4, 1);
          i0.ɵɵlistener("waResizeObserver", function TuiBadgedContentComponent_Template_div_waResizeObserver_6_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(0);
          });
          i0.ɵɵprojection(8, 3);
          i0.ɵɵelementStart(9, "div", 3);
          i0.ɵɵprojection(10, 4);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          const badgeTop_r2 = i0.ɵɵreference(2);
          const badgeBottom_r3 = i0.ɵɵreference(7);
          i0.ɵɵadvance();
          i0.ɵɵstyleProp("--t-badge-height", badgeTop_r2.offsetHeight, "px");
          i0.ɵɵadvance(5);
          i0.ɵɵstyleProp("--t-badge-height", badgeBottom_r3.offsetHeight, "px");
        }
      },
      dependencies: [WaResizeObserver],
      styles: ["[_nghost-%COMP%]{--tui-radius: var(--tui-radius-l);--t-badge-height: 0;--t-corner-offset: calc((var(--tui-radius) * 1.4142 - var(--tui-radius)) * 1 / 1.4142);position:relative;display:inline-block;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;width:-webkit-max-content;width:max-content;color:var(--tui-background-base)}.t-badge[_ngcontent-%COMP%]{--t-offset: calc(var(--t-badge-height) * -.5 + var(--t-corner-offset));position:absolute;display:flex}.t-badge_top[_ngcontent-%COMP%]{top:var(--t-offset);right:var(--t-offset)}.t-badge_bottom[_ngcontent-%COMP%]{bottom:var(--t-offset);right:var(--t-offset)}.t-border[_ngcontent-%COMP%]:not(:empty){display:flex;border-radius:6rem;box-shadow:0 0 0 1px currentColor;background:currentColor}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiBadgedContentComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-badged-content',
      imports: [TuiBadgedContentDirective, WaResizeObserver],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-content />\n\n<div\n    #badgeTop\n    class=\"t-badge t-badge_top\"\n    [style.--t-badge-height.px]=\"badgeTop.offsetHeight\"\n    (waResizeObserver)=\"(0)\"\n>\n    <ng-content select=\"tui-badge-notification[tuiSlot='top']\" />\n    <div class=\"t-border\">\n        <ng-content select=\"[tuiSlot='top']\" />\n    </div>\n</div>\n\n<div\n    #badgeBottom\n    class=\"t-badge t-badge_bottom\"\n    [style.--t-badge-height.px]=\"badgeBottom.offsetHeight\"\n    (waResizeObserver)=\"(0)\"\n>\n    <ng-content select=\"tui-badge-notification[tuiSlot='bottom']\" />\n    <div class=\"t-border\">\n        <ng-content select=\"[tuiSlot='bottom']\" />\n    </div>\n</div>\n",
      styles: [":host{--tui-radius: var(--tui-radius-l);--t-badge-height: 0;--t-corner-offset: calc((var(--tui-radius) * 1.4142 - var(--tui-radius)) * 1 / 1.4142);position:relative;display:inline-block;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;width:-webkit-max-content;width:max-content;color:var(--tui-background-base)}.t-badge{--t-offset: calc(var(--t-badge-height) * -.5 + var(--t-corner-offset));position:absolute;display:flex}.t-badge_top{top:var(--t-offset);right:var(--t-offset)}.t-badge_bottom{bottom:var(--t-offset);right:var(--t-offset)}.t-border:not(:empty){display:flex;border-radius:6rem;box-shadow:0 0 0 1px currentColor;background:currentColor}\n"]
    }]
  }], null, null);
})();
const TuiBadgedContent = [TuiBadgedContentDirective, TuiBadgedContentComponent];

/**
 * Generated bundle index. Do not edit.
 */

export { TuiBadgedContent, TuiBadgedContentComponent, TuiBadgedContentDirective };
