import * as i0 from '@angular/core';
import { inject, EventEmitter, TemplateRef, Component, ChangeDetectionStrategy, ContentChild, ViewChild, Input, Output, HostBinding, Directive, HostListener, NgModule } from '@angular/core';
import { TUI_STRICT_MATCHER } from '@taiga-ui/cdk/constants';
import { tuiIsNativeFocused } from '@taiga-ui/cdk/utils/focus';
import { tuiIsPresent } from '@taiga-ui/cdk/utils/miscellaneous';
import { tuiAsDataListHost, tuiAsOptionContent, TUI_DATA_LIST_ACCESSOR, TuiDataListDirective } from '@taiga-ui/core/components/data-list';
import * as i1$1 from '@taiga-ui/core/directives/dropdown';
import { TuiDropdownOpen, TuiDropdown } from '@taiga-ui/core/directives/dropdown';
import { TUI_ITEMS_HANDLERS } from '@taiga-ui/kit/tokens';
import { AbstractTuiNullableControl, tuiAsControl, AbstractTuiTextfieldHost } from '@taiga-ui/legacy/classes';
import { TUI_ARROW_MODE, TuiArrowComponent } from '@taiga-ui/legacy/components/arrow';
import * as i3 from '@taiga-ui/legacy/components/primitive-textfield';
import { TuiPrimitiveTextfieldComponent, TuiPrimitiveTextfieldModule, TuiTextfieldComponent } from '@taiga-ui/legacy/components/primitive-textfield';
import { TUI_SELECT_OPTION, TuiSelectOptionModule } from '@taiga-ui/legacy/components/select-option';
import * as i4 from '@taiga-ui/legacy/directives';
import { TUI_TEXTFIELD_SIZE, TuiWrapperModule, TuiTextfieldControllerModule, TuiLegacyDropdownOpenMonitorDirective } from '@taiga-ui/legacy/directives';
import { tuiAsFocusableItemAccessor, tuiAsTextfieldHost } from '@taiga-ui/legacy/tokens';
import { FIXED_DROPDOWN_CONTROLLER_PROVIDER } from '@taiga-ui/legacy/utils';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@taiga-ui/polymorpheus';
import { PolymorpheusOutlet, PolymorpheusTemplate } from '@taiga-ui/polymorpheus';
const _c0 = ["*", [["input"]]];
const _c1 = ["*", "input"];
const _c2 = (a0, a1) => ({
  $implicit: a0,
  active: a1
});
function TuiComboBoxComponent_div_4_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r2 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r2, " ");
  }
}
function TuiComboBoxComponent_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵtemplate(1, TuiComboBoxComponent_div_4_ng_container_1_Template, 2, 1, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r2.computedContent)("polymorpheusOutletContext", i0.ɵɵpureFunction2(2, _c2, ctx_r2.value, ctx_r2.computedFocused));
  }
}
function TuiComboBoxComponent_ng_template_5_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiComboBoxComponent_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵtemplate(1, TuiComboBoxComponent_ng_template_5_ng_container_1_Template, 1, 0, "ng-container", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r2.arrow);
  }
}
class TuiComboBoxComponent extends AbstractTuiNullableControl {
  constructor() {
    super(...arguments);
    this.arrowMode = inject(TUI_ARROW_MODE);
    this.itemsHandlers = inject(TUI_ITEMS_HANDLERS);
    this.textfieldSize = inject(TUI_TEXTFIELD_SIZE);
    this.stringify = this.itemsHandlers.stringify;
    this.strictMatcher = TUI_STRICT_MATCHER;
    this.identityMatcher = this.itemsHandlers.identityMatcher;
    this.strict = true;
    this.search = null;
    this.searchChange = new EventEmitter();
    this.open = false;
  }
  get size() {
    return this.textfieldSize.size;
  }
  get nativeFocusableElement() {
    return this.textfield?.nativeFocusableElement ?? null;
  }
  get focused() {
    return tuiIsNativeFocused(this.nativeFocusableElement) || !!this.dropdown?.tuiDropdownOpen;
  }
  get nativeValue() {
    return this.value === null ? this.search || '' : this.stringify(this.value);
  }
  checkOption(option) {
    if (!this.isStrictMatch(option)) {
      return;
    }
    this.value = option;
    this.updateSearch(null);
  }
  handleOption(item) {
    this.focusInput();
    this.close();
    this.updateSearch(null);
    this.value = item;
    if (this.value) {
      this.setNativeValue(this.stringify(item));
    }
  }
  onValueChange(value) {
    this.updateSearch(value);
    const match = this.accessor?.getOptions().find(item => this.isStrictMatch(item));
    if (match !== undefined) {
      this.value = match;
      this.updateSearch(null);
      return;
    }
    if (this.strict || this.search === '') {
      this.value = null;
    }
    // Clearing sets the empty value, the dropdown should not be opened on clear.
    if (this.search !== '') {
      this.open = true;
    }
  }
  toggle() {
    this.open = !this.open;
  }
  get arrow() {
    return !this.interactive ? this.arrowMode.disabled : this.arrowMode.interactive;
  }
  get showValueTemplate() {
    return tuiIsPresent(this.value) && !this.focused;
  }
  get computedContent() {
    return this.valueContent || this.nativeValue;
  }
  onActiveZone(active) {
    this.updateFocused(active);
  }
  onFieldKeyDownEnter(event) {
    if (this.open) {
      event.preventDefault();
    }
    const options = this.accessor?.getOptions() || [];
    if (options.length !== 1) {
      return;
    }
    this.value = options[0];
    this.updateSearch(null);
    this.close();
  }
  isStrictMatch(item) {
    return !!this.search && !!this.strictMatcher?.(item, this.search, this.stringify);
  }
  close() {
    this.open = false;
  }
  updateSearch(search) {
    if (this.search === search) {
      return;
    }
    this.search = search;
    this.searchChange.emit(search);
  }
  setNativeValue(value) {
    if (this.nativeFocusableElement) {
      this.nativeFocusableElement.value = value;
    }
  }
  focusInput(preventScroll = false) {
    if (this.nativeFocusableElement) {
      this.nativeFocusableElement.focus({
        preventScroll
      });
    }
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiComboBoxComponent_BaseFactory;
      return function TuiComboBoxComponent_Factory(__ngFactoryType__) {
        return (ɵTuiComboBoxComponent_BaseFactory || (ɵTuiComboBoxComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiComboBoxComponent)))(__ngFactoryType__ || TuiComboBoxComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiComboBoxComponent,
      selectors: [["tui-combo-box"]],
      contentQueries: function TuiComboBoxComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, TUI_DATA_LIST_ACCESSOR, 5);
          i0.ɵɵcontentQuery(dirIndex, TuiDataListDirective, 5, TemplateRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.accessor = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.datalist = _t.first);
        }
      },
      viewQuery: function TuiComboBoxComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(TuiDropdownOpen, 5);
          i0.ɵɵviewQuery(TuiPrimitiveTextfieldComponent, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dropdown = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textfield = _t.first);
        }
      },
      hostVars: 1,
      hostBindings: function TuiComboBoxComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("data-size", ctx.size);
        }
      },
      inputs: {
        stringify: "stringify",
        strictMatcher: "strictMatcher",
        identityMatcher: "identityMatcher",
        valueContent: "valueContent",
        strict: "strict",
        search: "search"
      },
      outputs: {
        searchChange: "searchChange"
      },
      features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiComboBoxComponent), tuiAsDataListHost(TuiComboBoxComponent), tuiAsControl(TuiComboBoxComponent), tuiAsOptionContent(TUI_SELECT_OPTION)], [FIXED_DROPDOWN_CONTROLLER_PROVIDER]), i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c1,
      decls: 7,
      vars: 13,
      consts: [["icon", ""], ["tuiDropdownOpenMonitor", "", 1, "t-hosted", 3, "tuiDropdownOpenChange", "tuiActiveZoneChange", "tuiDropdown", "tuiDropdownEnabled", "tuiDropdownOpen"], ["automation-id", "tui-combo-box__textfield", 1, "t-textfield", 3, "click", "keydown.enter", "valueChange", "disabled", "focusable", "invalid", "nativeId", "pseudoFocus", "pseudoHover", "readOnly", "tuiTextfieldIcon", "value"], ["automation-id", "tui-combo-box__template", "ngProjectAs", "tuiContent", "class", "t-value", 5, ["tuiContent"], 4, "ngIf"], ["automation-id", "tui-combo-box__template", "ngProjectAs", "tuiContent", 5, ["tuiContent"], 1, "t-value"], [4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["appearance", "icon", "tuiWrapper", "", 1, "t-icon"], [4, "polymorpheusOutlet"]],
      template: function TuiComboBoxComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 1);
          i0.ɵɵtwoWayListener("tuiDropdownOpenChange", function TuiComboBoxComponent_Template_div_tuiDropdownOpenChange_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            i0.ɵɵtwoWayBindingSet(ctx.open, $event) || (ctx.open = $event);
            return i0.ɵɵresetView($event);
          });
          i0.ɵɵlistener("tuiActiveZoneChange", function TuiComboBoxComponent_Template_div_tuiActiveZoneChange_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onActiveZone($event));
          });
          i0.ɵɵelementStart(1, "tui-primitive-textfield", 2);
          i0.ɵɵlistener("click", function TuiComboBoxComponent_Template_tui_primitive_textfield_click_1_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.toggle());
          })("keydown.enter", function TuiComboBoxComponent_Template_tui_primitive_textfield_keydown_enter_1_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onFieldKeyDownEnter($event));
          })("valueChange", function TuiComboBoxComponent_Template_tui_primitive_textfield_valueChange_1_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onValueChange($event));
          });
          i0.ɵɵprojection(2);
          i0.ɵɵprojection(3, 1, ["ngProjectAs", "input", 5, ["input"]]);
          i0.ɵɵtemplate(4, TuiComboBoxComponent_div_4_Template, 2, 5, "div", 3);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(5, TuiComboBoxComponent_ng_template_5_Template, 2, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          const icon_r4 = i0.ɵɵreference(6);
          i0.ɵɵproperty("tuiDropdown", ctx.datalist || "")("tuiDropdownEnabled", ctx.interactive && !!ctx.datalist);
          i0.ɵɵtwoWayProperty("tuiDropdownOpen", ctx.open);
          i0.ɵɵadvance();
          i0.ɵɵproperty("disabled", ctx.computedDisabled)("focusable", ctx.computedFocusable)("invalid", ctx.computedInvalid)("nativeId", ctx.nativeId)("pseudoFocus", ctx.computedFocused)("pseudoHover", ctx.pseudoHover)("readOnly", ctx.readOnly)("tuiTextfieldIcon", ctx.arrow ? icon_r4 : "")("value", ctx.nativeValue);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("ngIf", ctx.showValueTemplate);
        }
      },
      dependencies: [i1.NgIf, i2.PolymorpheusOutlet, i3.TuiPrimitiveTextfieldComponent, i3.TuiPrimitiveTextfieldDirective, i4.TuiWrapperDirective, i4.TuiTextfieldIconDirective, i4.TuiLegacyDropdownOpenMonitorDirective, i1$1.TuiDropdownDirective, i1$1.TuiDropdownOpen],
      styles: ["[_nghost-%COMP%]{display:block;border-radius:var(--tui-radius-m);text-align:left}.t-hosted[_ngcontent-%COMP%]{display:block;border-radius:inherit}.t-textfield[_ngcontent-%COMP%]{border-radius:inherit;text-align:inherit}.t-value[_ngcontent-%COMP%]{display:flex;width:100%;align-items:center}.t-icon[_ngcontent-%COMP%]{pointer-events:auto}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiComboBoxComponent, [{
    type: Component,
    args: [{
      selector: 'tui-combo-box',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiComboBoxComponent), tuiAsDataListHost(TuiComboBoxComponent), tuiAsControl(TuiComboBoxComponent), tuiAsOptionContent(TUI_SELECT_OPTION)],
      viewProviders: [FIXED_DROPDOWN_CONTROLLER_PROVIDER],
      template: "<div\n    tuiDropdownOpenMonitor\n    class=\"t-hosted\"\n    [tuiDropdown]=\"datalist || ''\"\n    [tuiDropdownEnabled]=\"interactive && !!datalist\"\n    [(tuiDropdownOpen)]=\"open\"\n    (tuiActiveZoneChange)=\"onActiveZone($event)\"\n>\n    <tui-primitive-textfield\n        automation-id=\"tui-combo-box__textfield\"\n        class=\"t-textfield\"\n        [disabled]=\"computedDisabled\"\n        [focusable]=\"computedFocusable\"\n        [invalid]=\"computedInvalid\"\n        [nativeId]=\"nativeId\"\n        [pseudoFocus]=\"computedFocused\"\n        [pseudoHover]=\"pseudoHover\"\n        [readOnly]=\"readOnly\"\n        [tuiTextfieldIcon]=\"arrow ? icon : ''\"\n        [value]=\"nativeValue\"\n        (click)=\"toggle()\"\n        (keydown.enter)=\"onFieldKeyDownEnter($event)\"\n        (valueChange)=\"onValueChange($event)\"\n    >\n        <ng-content />\n        <ng-content\n            ngProjectAs=\"input\"\n            select=\"input\"\n        />\n        <div\n            *ngIf=\"showValueTemplate\"\n            automation-id=\"tui-combo-box__template\"\n            ngProjectAs=\"tuiContent\"\n            class=\"t-value\"\n        >\n            <ng-container\n                *polymorpheusOutlet=\"computedContent as text; context: {$implicit: value!, active: computedFocused}\"\n            >\n                {{ text }}\n            </ng-container>\n        </div>\n    </tui-primitive-textfield>\n\n    <ng-template #icon>\n        <div\n            appearance=\"icon\"\n            tuiWrapper\n            class=\"t-icon\"\n        >\n            <ng-container *polymorpheusOutlet=\"arrow\" />\n        </div>\n    </ng-template>\n</div>\n",
      styles: [":host{display:block;border-radius:var(--tui-radius-m);text-align:left}.t-hosted{display:block;border-radius:inherit}.t-textfield{border-radius:inherit;text-align:inherit}.t-value{display:flex;width:100%;align-items:center}.t-icon{pointer-events:auto}\n"]
    }]
  }], null, {
    accessor: [{
      type: ContentChild,
      args: [TUI_DATA_LIST_ACCESSOR]
    }],
    dropdown: [{
      type: ViewChild,
      args: [TuiDropdownOpen]
    }],
    textfield: [{
      type: ViewChild,
      args: [TuiPrimitiveTextfieldComponent]
    }],
    datalist: [{
      type: ContentChild,
      args: [TuiDataListDirective, {
        read: TemplateRef
      }]
    }],
    stringify: [{
      type: Input
    }],
    strictMatcher: [{
      type: Input
    }],
    identityMatcher: [{
      type: Input
    }],
    valueContent: [{
      type: Input
    }],
    strict: [{
      type: Input
    }],
    search: [{
      type: Input
    }],
    searchChange: [{
      type: Output
    }],
    size: [{
      type: HostBinding,
      args: ['attr.data-size']
    }]
  });
})();
class TuiComboBoxDirective extends AbstractTuiTextfieldHost {
  get value() {
    return this.host.nativeValue;
  }
  onValueChange(value) {
    this.host.onValueChange(value);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiComboBoxDirective_BaseFactory;
      return function TuiComboBoxDirective_Factory(__ngFactoryType__) {
        return (ɵTuiComboBoxDirective_BaseFactory || (ɵTuiComboBoxDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiComboBoxDirective)))(__ngFactoryType__ || TuiComboBoxDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiComboBoxDirective,
      selectors: [["tui-combo-box"]],
      features: [i0.ɵɵProvidersFeature([tuiAsTextfieldHost(TuiComboBoxDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiComboBoxDirective, [{
    type: Directive,
    args: [{
      selector: 'tui-combo-box',
      providers: [tuiAsTextfieldHost(TuiComboBoxDirective)]
    }]
  }], null, null);
})();
class TuiComboBoxStrictDirective {
  constructor() {
    this.comboBox = inject(TuiComboBoxComponent);
    this.strict = true;
  }
  onInput() {
    if (!this.strict && this.comboBox.search) {
      this.comboBox.value = this.comboBox.search;
    }
  }
  static {
    this.ɵfac = function TuiComboBoxStrictDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiComboBoxStrictDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiComboBoxStrictDirective,
      selectors: [["tui-combo-box", "strict", ""]],
      hostBindings: function TuiComboBoxStrictDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("input", function TuiComboBoxStrictDirective_input_HostBindingHandler() {
            return ctx.onInput();
          });
        }
      },
      inputs: {
        strict: "strict"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiComboBoxStrictDirective, [{
    type: Directive,
    args: [{
      selector: 'tui-combo-box[strict]'
    }]
  }], null, {
    strict: [{
      type: Input
    }],
    onInput: [{
      type: HostListener,
      args: ['input']
    }]
  });
})();
class TuiComboBoxModule {
  static {
    this.ɵfac = function TuiComboBoxModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiComboBoxModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TuiComboBoxModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, TuiPrimitiveTextfieldModule, TuiSelectOptionModule, TuiArrowComponent, TuiWrapperModule, TuiTextfieldControllerModule, i1$1.TuiDropdownComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiComboBoxModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PolymorpheusOutlet, PolymorpheusTemplate, TuiPrimitiveTextfieldModule, TuiSelectOptionModule, TuiArrowComponent, TuiWrapperModule, TuiTextfieldControllerModule, TuiLegacyDropdownOpenMonitorDirective, ...TuiDropdown],
      declarations: [TuiComboBoxComponent, TuiComboBoxStrictDirective, TuiComboBoxDirective],
      exports: [TuiComboBoxComponent, TuiComboBoxStrictDirective, TuiComboBoxDirective, TuiTextfieldComponent, ...TuiDropdown]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiComboBoxComponent, TuiComboBoxDirective, TuiComboBoxModule, TuiComboBoxStrictDirective };
