import { Component, EventEmitter, Output } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiIcon } from '@taiga-ui/core';
import { TuiHeader } from '@taiga-ui/layout';

@Component({
	standalone: true,
	imports: [TuiButton, TuiIcon, TuiHeader, TranslocoModule],
	selector: 'ctx-manage-folder-dialog',
	templateUrl: 'manage-folder-dialog.component.html',
})
export class ManageFolderDialogComponent {
	@Output() dismiss = new EventEmitter();

	@Output() edit = new EventEmitter();

	@Output() moveTo = new EventEmitter();

	onEdit() {
		this.edit.emit();
	}

	onMoveTo() {
		this.moveTo.emit();
	}

	onDismiss() {
		this.dismiss.emit();
	}
}
