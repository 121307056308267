<div class="mobile-menu-dialog__header">
	<h3 class="color-secondary">{{ 'app.name' | transloco }}</h3>
	<button
		class="secondary"
		tuiIconButton
		appearance="ghost"
		size="m"
		(click)="onDismiss()"
	>
		<tui-icon size="l" icon="@tui.x"></tui-icon>
	</button>
</div>

<div class="mobile-menu-dialog__content">
	<header tuiHeader class="mobile-menu-dialog__content-profile">
		<ctx-avatar
			[routerLink]="'profile'"
			(click)="onDismiss()"
			size="xl"
			[user]="authService.user"
		></ctx-avatar>

		<div class="mobile-menu-dialog__content-profile-info">
			<p class="color-primary margin-v-0 font-size-xlarge font-bold">
				{{ authService.user | userName }}
			</p>
			<p class="color-tertiary margin-v-0 font-size-small">
				{{ authService.organization?.name }}
			</p>
		</div>
	</header>

	<div class="mobile-menu-dialog__content-navigation">
		@for (item of menuItems; track item.key) {
			@if (item.location === 'side' || item.location === 'all') {
				@if (item.handler) {
					<button
						tuiLink
						size="l"
						class="menu-button"
						[iconStart]="item.icon"
						(click)="item.handler()"
					>
						{{ 'navigation.' + item.key | transloco }}
					</button>
				} @else {
					<a
						tuiLink
						size="l"
						class="menu-button"
						[iconStart]="item.icon"
						[routerLink]="[item.route]"
						routerLinkActive="active"
						(click)="onDismiss()"
					>
						{{ 'navigation.' + item.key | transloco }}
					</a>
				}
			}
		}
	</div>
</div>
