@if (loading) {
	<tui-loader size="xs" />
} @else {
	@if (showHome) {
		<ng-container
			*ngTemplateOutlet="
				anchorContent;
				context: { segment: homePath, last: !path?.length }
			"
		></ng-container>
	}

	@for (segment of path; track segment.id) {
		<ng-container
			*ngTemplateOutlet="anchorContent; context: { segment, last: $last }"
		></ng-container>
	}
}

<ng-template #anchorContent let-segment="segment" let-last="last">
	<a
		tuiLink
		appearance="ghost"
		size="s"
		[routerLink]="segment | contentUrl"
		[tuiHint]="segment.name"
		tuiHintAppearance="dark"
	>
		{{ segment.name }}
	</a>

	@if (!last) {
		<tui-icon icon="@tui.chevron-right" />
	}
</ng-template>
