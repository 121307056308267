<header tuiHeader>
	@if (folder) {
		<h3 tuiTitle class="margin-0">
			{{ 'folder.edit-folder' | transloco }}
		</h3>
	} @else {
		<h3 tuiTitle class="margin-0">
			{{ 'folder.create-folder' | transloco }}
		</h3>
	}

	<div tuiAccessories>
		<button tuiIconButton size="m" (click)="onDismiss()" appearance="ghost">
			<tui-icon icon="@tui.x"></tui-icon>
		</button>
	</div>
</header>

<div class="folder-dialog__content padding-v-8">
	@if (loading) {
		<div class="padding-v-8">
			<tui-loader size="l" />
		</div>
	} @else if (form) {
		<form
			[formGroup]="form"
			(ngSubmit)="onSubmit()"
			(keyup.enter)="onSubmit()"
		>
			<label tuiLabel>
				{{ 'data.name' | transloco }}*

				<tui-textfield tuiTextfieldSize="m">
					<input
						formControlName="name"
						tuiTextfield
						type="text"
						[placeholder]="
							'folder.folder-name-placeholder' | transloco
						"
					/>
				</tui-textfield>

				<tui-error
					formControlName="name"
					[error]="['required'] | tuiFieldError | async"
				/>
			</label>

			<label tuiLabel>
				{{ 'data.description' | transloco }}

				<tui-textarea
					tuiTextfieldSize="m"
					formControlName="description"
					[tuiTextfieldLabelOutside]="true"
					[expandable]="true"
					[maxLength]="maxLength"
				></tui-textarea>
			</label>

			@if (!editing) {
				<label tuiLabel>
					{{ 'data.save-to' | transloco }}*

					<tui-combo-box
						tuiDropdownMobile
						formControlName="parent"
						tuiTextfieldSize="m"
						[tuiTextfieldLabelOutside]="true"
						[valueContent]="folderContent"
					>
						<tui-data-list-wrapper
							*tuiDataList
							[itemContent]="folderContent"
							[items]="folderOptions | tuiFilterByInput"
						>
						</tui-data-list-wrapper>

						<ng-template #folderContent let-option>
							<ctx-content-row
								size="s"
								[content]="option.data"
								[label]="option.path"
								[asTableRow]="false"
							></ctx-content-row>
						</ng-template>
					</tui-combo-box>

					<tui-error
						formControlName="parent"
						[error]="['required'] | tuiFieldError | async"
					/>
				</label>
			}
		</form>
	}
</div>

<footer tuiFooter>
	<button
		tuiButton
		appearance="outline"
		[disabled]="busy"
		(click)="onDismiss()"
	>
		{{ 'action.cancel' | transloco }}
	</button>
	<button
		tuiButton
		appearance="accent"
		type="submit"
		[disabled]="busy"
		[loading]="busy"
		(click)="onSubmit()"
	>
		@if (editing) {
			{{ 'action.save' | transloco }}
		} @else {
			{{ 'action.create' | transloco }}
		}
	</button>
</footer>
