import { ContentFile } from '../types';

export function createFile(
	payload: Partial<ContentFile>,
): Omit<
	ContentFile,
	'createdAt' | 'updatedAt' | 'deletedAt' | 'createdBy' | 'id'
> {
	if (!payload.name) throw new Error('A name is required');
	if (!payload.status) throw new Error('A status ref is required');

	return {
		...payload,
		type: 'file',
		name: payload.name,
		status: payload.status,
		path: payload.path ?? null,
		parent: payload.parent ?? null,
		description: payload.description ?? null,
		thumbnails: payload.thumbnails ?? [],
	};
}
