import { ContentFolder } from '../types';

export function createFolder(
	payload: Partial<ContentFolder>,
): Omit<
	ContentFolder,
	'createdAt' | 'updatedAt' | 'createdBy' | 'deletedAt' | 'id'
> {
	if (!payload.name) throw new Error('Folder name is required');

	return {
		...payload,
		type: 'folder',
		name: payload.name,
		description: payload.description ?? null,
		path: payload.path ?? null,
		parent: payload.parent ?? null,
		thumbnails: payload.thumbnails ?? [],
	};
}
