import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AlertService } from '@context/frontend/alert';
import { ContentRowComponent, ContentService } from '@context/frontend/content';
import { FormValidators } from '@context/frontend/form';
import { Content } from '@context/shared/types/common';
import { ContentFolder, FolderOption } from '@context/shared/types/folder';
import { TranslocoModule } from '@jsverse/transloco';
import {
	TuiButton,
	TuiDataList,
	TuiError,
	TuiIcon,
	TuiLoader,
} from '@taiga-ui/core';
import {
	TuiButtonLoading,
	TuiDataListWrapper,
	TuiFieldErrorPipe,
	TuiFilterByInputPipe,
} from '@taiga-ui/kit';
import { TuiHeader } from '@taiga-ui/layout';
import {
	TuiComboBoxModule,
	TuiTextfieldControllerModule,
} from '@taiga-ui/legacy';
import { DocumentReference } from 'firebase/firestore';

const HomeFolderOption = new FolderOption(
	{
		id: 'home',
		type: 'folder',
		name: 'Home',
	} as ContentFolder,
	null,
	null,
);

@Component({
	standalone: true,
	imports: [
		TuiHeader,
		TuiIcon,
		TuiButton,
		TranslocoModule,
		ReactiveFormsModule,
		TuiError,
		TuiFieldErrorPipe,
		AsyncPipe,
		TuiButtonLoading,
		TuiTextfieldControllerModule,
		TuiDataList,
		TuiDataListWrapper,
		TuiLoader,
		TuiComboBoxModule,
		TuiFilterByInputPipe,
		ContentRowComponent,
	],
	selector: 'ctx-move-to-folder-dialog',
	templateUrl: 'move-to-folder-dialog.component.html',
})
export class MoveToFolderDialogComponent
	extends FormValidators
	implements OnInit
{
	/**
	 * If folder is provided, then we can assume `edit` mode.
	 * If folder is not provided, then we can assume `create` mode.
	 */
	@Input()
	content!: Content;

	@Output()
	dismiss = new EventEmitter<Content | null>();

	busy = false;

	form: null | FormGroup = null;

	loading = false;

	folderOptions: FolderOption[] = [HomeFolderOption];

	constructor(
		private readonly contentService: ContentService,
		private readonly alert: AlertService,
	) {
		super();
	}

	ngOnInit() {
		this.setupForm();
	}

	async setupForm() {
		this.loading = true;

		await this.fetchFolders().catch(() => {
			this.alert.open('generic-error').subscribe();
			this.onDismiss();

			throw new Error('There was an issue fetching the possible folders');
		});

		const parent = this.folderOptions.find(
			(option) => option.ref?.id === this.content.parent?.id,
		);

		this.form = new FormGroup({
			parent: new FormControl(parent, (field) =>
				this.getRequiredValidator(field, 'form.error.parent.required'),
			),
		});

		this.loading = false;
	}

	fetchFolders() {
		return this.contentService
			.fetchFolderReferences({ fetchAll: true })
			.then(async (res) => {
				const docs = res.docs;

				for (const doc of docs) {
					const folder = doc.data() as ContentFolder;
					const path = (
						await this.contentService.generateFolderPath(folder)
					)?.map((p) => p.name);

					const itselfOrNested =
						folder.id === this.content.id ||
						folder.path?.some((p) => p.id === this.content.id);

					// cannot choose itself
					if (!itselfOrNested) {
						this.folderOptions.push(
							new FolderOption(
								folder,
								doc.ref as DocumentReference<ContentFolder>,
								path?.length ? path.join(' > ') : null,
							),
						);
					}
				}
			});
	}

	onSubmit() {
		this.form?.markAllAsTouched();
		if (this.busy || !this.form || this.form.invalid) return;

		this.form.disable();
		this.busy = true;

		const value = this.form.getRawValue();
		const moveTo = (value.parent as FolderOption).valueOf();

		return this.contentService
			.moveContentToFolder(
				this.content,
				moveTo as DocumentReference<ContentFolder> | null,
			)
			.then((folder) => {
				this.dismiss.emit(folder);
				this.alert.open('generic-success').subscribe();
			})
			.catch((error) => {
				console.error('There was an issue updating the folder', error);
				this.alert.open('generic-error').subscribe();
			})
			.finally(() => {
				this.busy = false;
				this.form?.enable();
			});
	}

	onDismiss() {
		this.dismiss.emit(null);
	}
}
