import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
} from '@angular/core';
import { Content } from '@context/shared/types/common';
import {
	TuiFallbackSrcPipe,
	TuiIcon,
	TuiIconPipe,
	TuiSizeL,
	TuiSizeS,
} from '@taiga-ui/core';
import { TuiAvatar } from '@taiga-ui/kit';

const IconMap: { [key in 'folder' | 'file']: string } = {
	file: '@tui.sprout',
	folder: '@tui.folder',
};

@Component({
	standalone: true,
	imports: [TuiAvatar, TuiIcon, TuiIconPipe, TuiFallbackSrcPipe, AsyncPipe],
	selector: 'ctx-content-thumbnail',
	templateUrl: 'content-thumbnail.component.html',
	styleUrl: 'content-thumbnail.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentThumbnailComponent {
	@Input()
	content!: Content;

	@HostBinding('attr.size')
	@Input()
	size: TuiSizeS | TuiSizeL = 'l';

	get icon() {
		return IconMap[this.content.type];
	}

	get thumbnailUrl() {
		if (!this.content.thumbnails?.length) return;

		return (
			this.content.thumbnails.find((t) => t.resolution === 512)?.url ??
			null
		);
	}
}
