import { Pipe, PipeTransform } from '@angular/core';
import { Content } from '@context/shared/types/common';

@Pipe({
	standalone: true,
	name: 'contentUrl',
})
export class ContentUrlPipe implements PipeTransform {
	transform(content: Content) {
		if (!content.id) return ['/home'];

		switch (content.type) {
			case 'file':
				return ['/home', 'file', content.id];
			case 'folder':
				return ['/home', 'folder', content.id];
			default:
				throw new Error(
					`The content type is not supported: ${content.type}`,
				);
		}
	}
}
