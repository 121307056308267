<ctx-content-thumbnail
	[size]="size"
	[content]="content"
></ctx-content-thumbnail>

<div class="content-row__information">
	@if (label) {
		<small class="content-row__information-label margin-0">
			{{ label }}
		</small>
	}

	<p class="margin-0">
		{{ content.name }}
	</p>

	@if (asTableRow) {
		<small class="margin-0">
			{{
				'data.last-edited-at'
					| transloco: { time: content.updatedAt | relativeTime }
			}}
		</small>
	}
</div>

@if (asTableRow) {
	<div class="content-row__actions">
		<tui-icon icon="@tui.arrow-right"></tui-icon>
	</div>
}
