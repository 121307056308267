import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
	Component,
	HostBinding,
	inject,
	TemplateRef,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import {
	Router,
	RouterLink,
	RouterLinkActive,
	RouterOutlet,
} from '@angular/router';
import { DestroyService } from '@context/frontend/common';
import { ContentService } from '@context/frontend/content';
import { FolderDialogComponent } from '@context/frontend/folder';
import { getUaDevice } from '@context/frontend/native/platform';
import { NotificationService } from '@context/frontend/notification';
import {
	AuthService,
	AvatarComponent,
	LogoutDialogComponent,
	RoleAccessPipe,
	UserNamePipe,
} from '@context/frontend/user';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiTabBar } from '@taiga-ui/addon-mobile';
import {
	TuiButton,
	TuiDialogContext,
	TuiDialogService,
	TuiIcon,
	TuiLink,
} from '@taiga-ui/core';
import { TuiBadgedContent, TuiBadgeNotification } from '@taiga-ui/kit';
import {
	PolymorpheusOutlet,
	PolymorpheusTemplate,
} from '@taiga-ui/polymorpheus';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { map, Subscription, takeUntil } from 'rxjs';
import { MobileMenuDialogComponent } from '../../dialogs';
import { EntityType, EntityTypeMenuItems, MenuItems } from './navigation.const';

@Component({
	standalone: true,
	imports: [
		RouterOutlet,
		TuiButton,
		TuiIcon,
		TranslocoModule,
		RouterLink,
		RouterLinkActive,
		TuiLink,
		RoleAccessPipe,
		AvatarComponent,
		UserNamePipe,
		TuiTabBar,
		MobileMenuDialogComponent,
		TuiBadgedContent,
		TuiBadgeNotification,
		AsyncPipe,
		LogoutDialogComponent,
		PolymorpheusTemplate,
		PolymorpheusOutlet,
		FolderDialogComponent,
		NgTemplateOutlet,
	],
	selector: 'ctx-navigation-template',
	templateUrl: 'navigation-template.component.html',
	styleUrl: 'navigation-template.component.scss',
	providers: [DestroyService],
	encapsulation: ViewEncapsulation.None,
})
export class NavigationTemplateComponent {
	@HostBinding('class.collapsed')
	sidebarCollapsed = false;

	@HostBinding('attr.device')
	readonly device = getUaDevice();

	@ViewChild('logoutTemplate')
	logoutTemplate?: TemplateRef<TuiDialogContext>;

	@ViewChild('createTemplate')
	createTemplate?: TemplateRef<TuiDialogContext>;

	@ViewChild('folderTemplate')
	folderTemplate?: TemplateRef<TuiDialogContext>;

	readonly notificationService = inject(NotificationService);

	readonly entityTypes = EntityTypeMenuItems;
	readonly menuItems = MenuItems({
		createHandler: () => this.onPromptCreate(),
		logoutHandler: () => this.onPromptLogout(),
		notificationCount$: this.notificationService.unreadNotificationCount$
			.asObservable()
			.pipe(
				map((count) => {
					if (!count) return null;
					if (count >= 100) return `${count}+`;
					return `${count}`;
				}),
			),
	});

	mobileMenuSubscription: Subscription | null = null;
	logoutDialogSubscription: Subscription | null = null;
	createDialogSubscription: Subscription | null = null;
	folderDialogSubscription: Subscription | null = null;

	constructor(
		public readonly authService: AuthService,
		private readonly router: Router,
		private readonly dialog: TuiDialogService,
		private readonly destroy$: DestroyService,
		private readonly contentService: ContentService,
	) {}

	onToggleSidebar() {
		this.sidebarCollapsed = !this.sidebarCollapsed;
	}

	onShowMobileMenu(content: PolymorpheusContent) {
		this.mobileMenuSubscription = this.dialog
			.open(content, { closeable: false, size: 'fullscreen' })
			.pipe(takeUntil(this.destroy$))
			.subscribe();
	}

	onPromptCreate() {
		this.createDialogSubscription = this.dialog
			.open(this.createTemplate, { closeable: false, size: 's' })
			.subscribe();
	}

	onPromptLogout() {
		this.logoutDialogSubscription = this.dialog
			.open(this.logoutTemplate, { closeable: false, size: 's' })
			.subscribe();
	}

	onCreate(entityType: EntityType) {
		switch (entityType) {
			case 'user':
				this.router.navigate(['users', 'invite']);
				break;
			case 'file': {
				const queryParams: any = {};
				const folderId = this.contentService.openFolderId.value;
				if (folderId) queryParams['folder'] = folderId;

				this.router.navigate(['home', 'file', 'create'], {
					queryParams,
				});
				break;
			}
			case 'folder':
				this.onCreateFolder();
				break;
			default:
				window.alert(`${entityType} not yet supported`);
		}

		this.createDialogSubscription?.unsubscribe();
	}

	onCreateFolder() {
		this.folderDialogSubscription = this.dialog
			.open(this.folderTemplate, { closeable: false, size: 's' })
			.subscribe();
	}

	onDismissLogout(logout: boolean) {
		if (logout) this.router.navigate(['/auth/logout']);
		this.logoutDialogSubscription?.unsubscribe();
	}

	onDismissMobile() {
		if (!this.mobileMenuSubscription) return;
		this.mobileMenuSubscription.unsubscribe();
	}

	onDismissCreate() {
		this.createDialogSubscription?.unsubscribe();
	}

	onDismissFolder() {
		this.folderDialogSubscription?.unsubscribe();
	}
}
