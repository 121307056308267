@if (sender) {
	<ctx-avatar
		size="l"
		[user]="sender"
		[tuiHint]="sender | userName"
	></ctx-avatar>
} @else {
	<ctx-avatar
		size="l"
		icon="@tui.bot"
		[tuiHint]="'notification.system-notification' | transloco"
	></ctx-avatar>
}

<div class="notification-row__information">
	<p
		class="margin-0"
		[innerHtml]="
			'notification.' + notification.type | transloco: notification.args
		"
	></p>
	<small>{{ notification.createdAt | relativeTime }}</small>
</div>

<div class="notification-row__actions">
	<ng-content></ng-content>
</div>
