import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AlertService } from '@context/frontend/alert';
import { ContentRowComponent, ContentService } from '@context/frontend/content';
import { FormValidators } from '@context/frontend/form';
import { createPath } from '@context/shared/types/common';
import {
	ContentFolder,
	FolderOption,
	HomeFolderOption,
} from '@context/shared/types/folder';
import { TranslocoModule } from '@jsverse/transloco';
import {
	TuiButton,
	TuiDataList,
	TuiError,
	TuiIcon,
	TuiLoader,
	TuiTextfield,
} from '@taiga-ui/core';
import {
	TuiButtonLoading,
	TuiDataListWrapper,
	TuiFieldErrorPipe,
	TuiFilterByInputPipe,
} from '@taiga-ui/kit';
import { TuiHeader } from '@taiga-ui/layout';
import {
	TuiComboBoxModule,
	TuiTextareaModule,
	TuiTextfieldControllerModule,
} from '@taiga-ui/legacy';
import { DocumentReference } from 'firebase/firestore';

@Component({
	standalone: true,
	imports: [
		TuiHeader,
		TuiIcon,
		TuiButton,
		TranslocoModule,
		ReactiveFormsModule,
		TuiTextfield,
		TuiError,
		TuiFieldErrorPipe,
		AsyncPipe,
		TuiButtonLoading,
		TuiTextfieldControllerModule,
		TuiTextareaModule,
		TuiDataList,
		TuiDataListWrapper,
		TuiLoader,
		TuiComboBoxModule,
		TuiFilterByInputPipe,
		ContentRowComponent,
	],
	selector: 'ctx-folder-dialog',
	templateUrl: 'folder-dialog.component.html',
})
export class FolderDialogComponent extends FormValidators implements OnInit {
	/**
	 * If folder is provided, then we can assume `edit` mode.
	 * If folder is not provided, then we can assume `create` mode.
	 */
	@Input() folder: ContentFolder | null = null;

	@Output() dismiss = new EventEmitter<ContentFolder | null>();

	busy = false;

	form: null | FormGroup = null;

	editing = false;

	loading = false;

	folderOptions: FolderOption[] = [HomeFolderOption];

	constructor(
		private readonly contentService: ContentService,
		private readonly alert: AlertService,
	) {
		super();
	}

	ngOnInit() {
		this.setupForm();
	}

	async setupForm() {
		this.loading = true;
		this.editing = !!this.folder;

		await this.fetchFolders().catch(() => {
			this.alert.open('generic-error').subscribe();
			this.onDismiss();

			throw new Error('There was an issue fetching the possible folders');
		});

		this.form = new FormGroup({
			id: new FormControl(null),
			description: new FormControl(null),
			name: new FormControl(null, [
				(field) =>
					this.getRequiredValidator(
						field,
						'form.error.name.required',
					),
			]),
		});

		// we only want to add the path and parent updates if newly creating
		if (!this.editing) {
			const currentFolder = this.determineCurrentFolder();
			this.form.addControl('path', new FormControl(null));
			this.form.addControl(
				'parent',
				new FormControl(currentFolder, (field) =>
					this.getRequiredValidator(
						field,
						'form.error.parent.required',
					),
				),
			);
		}

		if (this.editing && this.folder) this.form.patchValue(this.folder);
		this.loading = false;
	}

	determineCurrentFolder() {
		const folderId = this.contentService.openFolderId.value;
		return (
			this.folderOptions.find((option) => option.ref?.id === folderId) ??
			HomeFolderOption
		);
	}

	fetchFolders() {
		return this.contentService
			.fetchFolderReferences({ fetchAll: true })
			.then(async (res) => {
				const docs = res.docs;

				for (const doc of docs) {
					const folder = doc.data() as ContentFolder;
					const path = (
						await this.contentService.generateFolderPath(folder)
					)?.map((p) => p.name);

					this.folderOptions.push(
						new FolderOption(
							folder,
							doc.ref as DocumentReference<ContentFolder>,
							path?.length ? path.join(' > ') : null,
						),
					);
				}
			});
	}

	onSubmit() {
		this.form?.markAllAsTouched();
		if (this.busy || !this.form || this.form.invalid) return;

		this.form.disable();
		this.busy = true;

		const value = this.form.getRawValue();

		if (this.editing) {
			return this.contentService
				.update(value, {
					addActivityLog: true,
					type: 'update',
				})
				.then((folder) => {
					this.dismiss.emit(folder as ContentFolder);
					this.alert.open('generic-success').subscribe();
				})
				.catch((error) => {
					console.error(
						'There was an issue updating the folder',
						error,
					);
					this.alert.open('generic-error').subscribe();
				})
				.finally(() => {
					this.busy = false;
					this.form?.enable();
				});
		}

		const parentValue = value.parent as FolderOption;
		value.parent = parentValue.valueOf();
		value.path = createPath(value, parentValue.data.path ?? null);

		return this.contentService
			.createFolder(value)
			.then((folder) => {
				this.dismiss.emit(folder as ContentFolder);
				this.alert.open('generic-success').subscribe();
			})
			.catch((error) => {
				console.error('There was an issue creating the folder', error);
				this.alert.open('generic-error').subscribe();
			})
			.finally(() => {
				this.busy = false;
				this.form?.enable();
			});
	}

	onDismiss() {
		this.dismiss.emit(null);
	}
}
